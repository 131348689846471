/*--------------------------------------------------------------
12. Tab
----------------------------------------------------------------*/
.cs_tab_links.cs_style_1 {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  border: 1px solid $border;
  padding: 12px 0px;
  border-left: 0;
  border-right: 0;
  margin-bottom: 50px;
  gap: 10px 20px;
  @media (max-width: 991px) {
    margin-bottom: 35px;
  }
  @media (max-width: 767px) {
    justify-content: center;
    gap: 5px 30px;
  }
  li {
    &.active,
    &:hover a {
      color: $primary;
    }
  }
}
.cs_tab_links.cs_style_2 {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 45px;
  margin: 60px 0 30px;
  @media (max-width: 991px) {
    gap: 5px 25px;
    font-size: 16px;
    line-height: 1.4em;
  }
  li {
    a {
      display: inline-block;
      position: relative;
      padding-bottom: 5px;
      color: $primary;

      &::before {
        content: '';
        position: absolute;
        height: 1px;
        width: 100%;
        background-color: $primary;
        bottom: 0;
        transform: scaleX(0);
        transform-origin: right center;
        transition: transform 0.3s ease;
      }
    }

    &.active {
      a {
        &::before {
          transform: scaleX(1);
          transform-origin: left center;
        }
      }
    }
  }
}
/*-------------------------------------
Tab content design
---------------------------------------*/

.cs_tabs_fade {
  .cs_tab {
    display: none;
    &.active {
      display: block;
    }
  }
}
//For both tab and slider
.cs_tabs {
  position: relative;
  .cs_tab {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    pointer-events: none;
    top: 0;
    width: 100%;
    transition: all 0.5s ease;
    &.active {
      opacity: 1;
      visibility: visible;
      position: initial;
      pointer-events: initial;
      top: 0;
    }
  }
}
