.counts {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    position: absolute;
    right: -8px;
    bottom: 22px;
    width: 15px;
    height: 15px;
    background-color: #f9f86c;
    color: #000;
    font-size: 14px;
    font-weight: 700;
    pointer-events: none;
    text-align: center; /* Ensure text is centered */
    padding: 2px; /* Add padding to prevent text from touching edges */
}

@media(max-width: 991px) {
    .counts {
        right: 45px;
        bottom: 20px;
    }
}
