/*--------------------------------------------------------------
22. Post
----------------------------------------------------------------*/
.cs_post.cs_style_1 {
  .cs_post_thumb {
    display: block;
    margin-bottom: 24px;
  }

  .cs_post_meta {
    margin-bottom: 5px;
  }

  .cs_post_title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-bottom: 12px;
  }
  .cs_post_subtitle {
    margin-bottom: 27px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    margin-bottom: 16px;
  }

  .cs_post_btn {
    position: relative;
    padding-bottom: 2px;

    &::after {
      content: '';
      width: 100%;
      height: 1px;
      background-color: $primary;
      position: absolute;
      left: 0;
      bottom: 0;
      transform: scaleX(0);
      transform-origin: right center;
      transition: transform 0.3s ease;
    }

    .cs_btn_arrow {
      position: absolute;
      top: 2px;
      right: -20px;
      transform: rotate(-35deg);
      transition: all 0.3s ease;
    }

    &:hover {
      &::after {
        transform: scaleX(1);
        transform-origin: left center;
      }
    }
  }
}

.cs_post.cs_style_2 {
  display: flex;
  align-items: center;
  gap: 20px;

  @media (max-width: 991px) {
    flex-direction: column;
  }

  .cs_post_thumb {
    flex: none;
    @media (max-width: 991px) {
      width: 100%;
    }
  }

  .cs_post_meta {
    margin-bottom: 5px;
  }

  .cs_post_title,
  .cs_post_subtitle {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .cs_post_title {
    margin-bottom: 12px;
  }
  .cs_post_subtitle {
    margin-bottom: 16px;
  }

  .cs_post_btn {
    position: relative;
    padding-bottom: 2px;

    &::after {
      content: '';
      width: 100%;
      height: 1px;
      background-color: $primary;
      position: absolute;
      left: 0;
      bottom: 0;
      transform: scaleX(0);
      transform-origin: right center;
      transition: transform 0.3s ease;
    }

    .cs_btn_arrow {
      position: absolute;
      top: 2px;
      right: -20px;
      transform: rotate(-35deg);
      transition: all 0.3s ease;
    }

    &:hover {
      &::after {
        transform: scaleX(1);
        transform-origin: left center;
      }
    }
  }
}

.cs_post.cs_style_3 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  @media (max-width: 1199px) {
    height: 450px;
  }
  .cs_post_info {
    padding: 35px;
  }
  .cs_post_meta {
    margin-bottom: 5px;
    color: rgba($white, 0.8);
  }
  .cs_post_title {
    color: $white;
    margin-bottom: 12px;
  }
  .cs_post_subtitle {
    margin-bottom: 16px;
    color: rgba($white, 0.8);
  }
  .cs_post_btn {
    position: relative;
    padding-bottom: 2px;

    &::after {
      content: '';
      width: 100%;
      height: 1px;
      background-color: $accent;
      position: absolute;
      left: 0;
      bottom: 0;
      transform: scaleX(0);
      transform-origin: right center;
      transition: transform 0.3s ease;
    }

    .cs_btn_arrow {
      position: absolute;
      top: 2px;
      right: -20px;
      transform: rotate(-35deg);
      transition: all 0.3s ease;
    }

    &:hover {
      &::after {
        transform: scaleX(1);
        transform-origin: left center;
      }
    }
  }
}
.cs_post.cs_style_4 {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 25px;
  &:last-child {
    margin-bottom: 0;
  }
  .cs_post_thumb {
    width: 60px;
    height: 60px;
    flex: none;
  }
  .cs_post_title,
  .cs_post_subtitle {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .cs_post_title {
    margin-bottom: 2px;
  }
}
