/*--------------------------------------------------------------
## Basic Color
----------------------------------------------------------------*/
$white: #ffffff;
$black: #000000;
$primary: #070707;
$secondary: #5e5e5e;
$border: rgba($secondary, 0.2);
$gray: rgba($primary, 0.1);
$accent: #9c9c9c;
