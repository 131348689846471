/* styles.css */
.product-image-container {
  width: 100%;
  height: 0;
  /* Set initial height to 0 */
  padding-top: 100%;
  /* Maintain 1:1 aspect ratio */
  position: relative;
}

.product-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* Fill the container */
  object-fit: fill !important;
  /* Ensure the image covers the entire container */
}

.disabled {
  // text-decoration: line-through;
  opacity: 0.3;
  color: #666666;
  background: #eee;
  /* Adjust the color as needed */
}

/* Mặc định ẩn slider 2 */
#slider2 {
  display: none;
}

/* Khi màn hình lớn hơn 400px, hiển thị slider 1, ẩn slider 2 */
@media (min-width: 770px) {
  #slider1 {
    display: block;
  }

  #slider2 {
    display: none;
  }
}

/* Khi màn hình nhỏ hơn hoặc bằng 400px, hiển thị slider 2, ẩn slider 1 */
@media (max-width: 769px) {
  #slider1 {
    display: none;
  }

  #slider2 {
    display: block;
  }
}