/* Tùy chỉnh màu nền của tab */
.tabsHome .ant-tabs-nav-list {
    padding: 0 20px;
}

.tabsHome .ant-tabs-tab {
    /* Màu nền tab */
    background-color: #fafafa !important;
    justify-content: flex-start !important;
    padding: 6px 24px !important;
    font-size: 14px !important;
    border-radius: 30px;
    border: none;
    transition: all 0.3s ease;
}

@media (min-width: 768px) {
    .tabsHome .ant-tabs-tab {
        /* Màu nền tab */
        background-color: #fafafa !important;
        justify-content: flex-start !important;
        padding: 10px 30px !important;
        font-size: 18px !important;
        border-radius: 30px;
        border: none;
        transition: all 0.3s ease;
    }
}

.tabsHome .ant-tabs-nav::before {
    border-bottom: none !important
}

.tabsHome .ant-tabs-tab-active {
    background-color: #fcaf17 !important;
}

.tabsHome .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: black !important;
}

.tabsHome .ant-tabs-ink-bar {
    display: none;
}

.tabsHome .ant-tabs-tab-btn {
    color: #949494 !important;
}

.tabsHome .ant-custom {
    padding-right: 50px !important;
    padding-left: 50px !important;
}