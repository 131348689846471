/*--------------------------------------------------------------
8. Isotope
----------------------------------------------------------------*/
.cs_isotope_filter.cs_style_1 {
  ul {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 25px;
    border: 1px solid $border;
    border-right-width: 0;
    border-left-width: 0;
    margin-bottom: 50px;
    padding: 12px 0;
    @media (max-width: 991px) {
      margin-bottom: 35px;
    }
    @media (max-width: 767px) {
      justify-content: center;
      gap: 5px 30px;
    }
  }

  li {
    &:hover,
    &.active {
      a {
        color: $primary;
      }
    }
  }
  &.cs_type_1 {
    ul {
      padding: 12px;
      border-left-width: 1px;
      border-right-width: 1px;
      border-radius: 1.6em;
    }
  }
}

/*--------------------------------------------------------------
8. Isotopee
----------------------------------------------------------------*/
.cs_isotope {
  transition: all 0.4s ease;
}

.cs_isotope.cs_has_gutter_24 {
  margin-left: -12px;
  margin-right: -12px;
  margin-bottom: -50px;
  .cs_isotope_item {
    padding: 0 12px;
    margin-bottom: 50px;
  }
  @media (max-width: 991px) {
    margin-bottom: -30px;
    .cs_isotope_item {
      margin-bottom: 30px;
    }
  }
}

.cs_isotope.cs_has_gutter_80 {
  margin-left: -40px;
  margin-right: -40px;
  margin-bottom: -80px;

  .cs_isotope_item {
    padding: 0 40px;
    margin-bottom: 80px;
  }
}

.cs_isotope.cs_has_gutter_40 {
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: -30px;

  .cs_isotope_item {
    padding: 0 20px;
    margin-bottom: 30px;
  }
}

.cs_isotope.cs_has_gutter_100 {
  margin-left: -50px;
  margin-right: -50px;
  margin-bottom: -40px;

  .cs_isotope_item {
    padding: 0 50px;
    margin-bottom: 40px;
  }
}

.cs_isotope_col_6 .cs_grid_sizer,
.cs_isotope_col_6 .cs_isotope_item {
  width: 16.666667%;
}

.cs_isotope_col_5 .cs_grid_sizer,
.cs_isotope_col_5 .cs_isotope_item {
  width: 20%;
}

.cs_isotope_col_4 .cs_grid_sizer,
.cs_isotope_col_4 .cs_isotope_item {
  width: 25%;
}

.cs_isotope_col_3 .cs_grid_sizer,
.cs_isotope_col_3 .cs_isotope_item {
  width: 33.333333%;
}

.cs_isotope_col_2 .cs_grid_sizer,
.cs_isotope_col_2 .cs_isotope_item {
  width: 50%;
}

.cs_isotope_col_1 .cs_grid_sizer,
.cs_isotope_col_1 .cs_isotope_item {
  width: 100%;
}

.cs_grid_sizer {
  width: 33.333333%;
}

.cs_isotope_col_5 .cs-w20,
.cs_isotope_col_4 .cs-w20,
.cs_isotope_col_3 .cs-w20,
.cs_isotope_col_2 .cs-w20,
.cs_isotope_col_1 .cs-w20 {
  width: 20%;
}

.cs_isotope_col_5 .cs-w25,
.cs_isotope_col_4 .cs-w25,
.cs_isotope_col_3 .cs-w25,
.cs_isotope_col_2 .cs-w25,
.cs_isotope_col_1 .cs-w25 {
  width: 25%;
}

.cs_isotope_col_5 .cs-w33,
.cs_isotope_col_4 .cs-w33,
.cs_isotope_col_3 .cs-w33,
.cs_isotope_col_2 .cs-w33,
.cs_isotope_col_1 .cs-w33 {
  width: 33.333333%;
}

.cs_isotope_col_5 .cs-w50,
.cs_isotope_col_4 .cs-w50,
.cs_isotope_col_3 .cs-w50,
.cs_isotope_col_2 .cs-w50,
.cs_isotope_col_1 .cs-w50 {
  width: 50%;
}

.cs_isotope_col_5 .cs-w66,
.cs_isotope_col_4 .cs-w66,
.cs_isotope_col_3 .cs-w66,
.cs_isotope_col_2 .cs-w66,
.cs_isotope_col_1 .cs-w66 {
  width: 66.666666%;
}

.cs_isotope_col_5 .cs-w100,
.cs_isotope_col_4 .cs-w100,
.cs_isotope_col_3 .cs-w100,
.cs_isotope_col_2 .cs-w100,
.cs_isotope_col_1 .cs-w100 {
  width: 100%;
}
@media screen and (max-width: 1199px) {
  .cs_isotope.cs_has_gutter_100 .cs_isotope_item {
    padding: 0 15px;
    margin-bottom: 30px;
  }

  .cs_isotope.cs_has_gutter_100 {
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: -30px;
  }

  .cs_isotope.cs_has_gutter_80 {
    margin-left: -12px;
    margin-right: -12px;
    margin-bottom: -25px;

    .cs_isotope_item {
      padding: 0 12px;
      margin-bottom: 25px;
    }
  }

  .cs_isotope_col_4 .cs_grid_sizer,
  .cs_isotope_col_4 .cs_isotope_item {
    width: 33.333333%;
  }
}

@media screen and (max-width: 991px) {
  .cs_isotope_col_4 .cs_grid_sizer,
  .cs_isotope_col_4 .cs_isotope_item {
    width: 50%;
  }

  .cs_isotope_col_4 .cs-w50 {
    width: 100%;
  }

  .cs_isotope_col_3 .cs_grid_sizer,
  .cs_isotope_col_3 .cs_isotope_item {
    width: 50%;
  }

  .cs_isotope_col_2 .cs_grid_sizer,
  .cs_isotope_col_2 .cs_isotope_item {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .cs_isotope_col_5 .cs-w50,
  .cs_isotope_col_4 .cs-w50,
  .cs_isotope_col_3 .cs-w50,
  .cs_isotope_col_2 .cs-w50,
  .cs_isotope_col_1 .cs-w50,
  .cs_isotope_col_5 .cs-w66,
  .cs_isotope_col_4 .cs-w66,
  .cs_isotope_col_3 .cs-w66,
  .cs_isotope_col_2 .cs-w66,
  .cs_isotope_col_1 .cs-w66 {
    width: 100%;
  }

  .cs_isotope_col_5 .cs_grid_sizer,
  .cs_isotope_col_5 .cs_isotope_item {
    width: 100%;
  }

  .cs_isotope_col_4 .cs_grid_sizer,
  .cs_isotope_col_4 .cs_isotope_item {
    width: 100%;
  }

  .cs_isotope_col_3 .cs_grid_sizer,
  .cs_isotope_col_3 .cs_isotope_item {
    width: 100%;
  }

  .cs_isotope_col_2 .cs_grid_sizer,
  .cs_isotope_col_2 .cs_isotope_item {
    width: 100%;
  }
}
