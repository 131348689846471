/* styles.css */
.product-image-container {
  width: 100%;
  height: 400px; /* Set initial height to 0 */
  padding-top: 100%; /* Maintain 1:1 aspect ratio */
  position: relative;

  @media (max-width: 767px) {
    height: 150px
  }
  @media (max-width: 575px) {
    height: 150px;
  }
  
}

.product-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; /* Fill the container */
  object-fit: cover; /* Ensure the image covers the entire container */
  transition: transform 0.3s ease;
}

.product-image:hover {
  transform: scale(0.9); /* Zoom in on hover */
}

.item-slide {
  height: 600px;
}

@media (max-width: 1240px) {
  .item-slide {
    height: 450px;
  }
}

@media (max-width: 768px) {
  .item-slide {
    height: 800px;
  }
}

@media (max-width: 767px) {
  .item-slide {
    height: 700px;
  }
}

@media (max-width: 480px) {
  .item-slide {
    height: 400px;
  }
}

@media (max-width: 300px) {
  .item-slide {
    height: initial
  }
}

