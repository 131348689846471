.cs_grid_list {
  display: grid;
  grid-template-columns: repeat(4, 1fr) !important;
  -webkit-column-gap: 1.5% !important;
  -moz-column-gap: 1.5% !important;
  column-gap: 1.5% !important;
}

@media (max-width: 767px) {
  .cs_grid_list {
    grid-template-columns: repeat(1, 1fr) !important;
    gap: 30px;
  }
}

/* From Uiverse.io by SteveBloX */
.card {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background: white;
  border: 1px solid white;
  box-shadow: 12px 17px 51px rgba(0, 0, 0, 0.22);
  backdrop-filter: blur(6px);
  border-radius: 17px;
  text-align: center;
  cursor: pointer;
  transition: all 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  font-weight: bolder;
  color: black;
}

.card:hover {
  border: 1px solid black;
  transform: scale(1.05);
}

.card:active {
  transform: scale(0.95) rotateZ(1.7deg);
}