@media (min-width: 540px) {
    .cart-item-row {
        display: table-row;
    }

    .mobile-cart-item-row {
        display: none;
    }
}

@media (max-width: 540px) {
    .cart-item-row {
        display: none;
    }

    .mobile-cart-item-row {
        display: table-row;
    }
}