/*--------------------------------------------------------------
6. Video Block
----------------------------------------------------------------*/
.cs_video_block.cs_style_1 {
  min-height: 600px;
  position: relative;

  .cs_video_block_in {
    border-radius: 20px;
    border: 6px solid #ffffff;
    box-shadow: 0px 10px 80px rgba(0, 55, 106, 0.2);
    backdrop-filter: blur(40px);
  }

  .cs_schedule_shape_1 {
    position: absolute;
    width: 400px;
    height: 320px;
    background: rgba(0, 194, 255, 0.08);
    border-radius: 30px 0px 0px 0px;
    left: -55px;
    top: -55px;
  }

  .cs_schedule_shape_2 {
    position: absolute;
    left: -34px;
    top: 48%;
  }

  .cs_video_block_img {
    height: 475px;
    width: 100%;
    background-size: cover;
    background-position: center;

    > div {
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      opacity: 0.7;
    }
  }
}

.cs_play_btn_container {
  width: 80px;
  height: 80px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  cursor: pointer;

  &::before {
    content: '';
    width: 100%;
    height: 100%;
    background: #f5faff;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;
    animation: playBtnAnim 3s linear infinite;
  }
}

.cs_play_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: $accent;
  border-radius: 50%;
  position: relative;
  z-index: 2;
}

@keyframes playBtnAnim {
  50% {
    transform: translate(-50%, -50%) scale(1.5);
    opacity: 0.5;
  }

  100% {
    transform: translate(-50%, -50%) scale(2);
    opacity: 0;
  }
}

/*--------------------------------------------------------------
6. Video Popup
----------------------------------------------------------------*/
.cs_pd_video .cs_video_open,
.cs_sample_img .cs_video_open {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 68px;
  transition: all 0.3s ease;
  line-height: 48px;
}

.cs_pd_video .cs_video_open:hover,
.cs_sample_img .cs_video_open:hover {
  color: rgba(255, 255, 255, 0.7);
}

.cs_video_popup {
  position: fixed;
  z-index: 1000;
  top: 0;
  width: 100%;
  height: 100%;
  left: -100%;
  transition-delay: 0.3s;
}

.cs_video_popup.active {
  left: 0;
  transition-delay: 0s;
  left: 0;
}

.cs_video_popup_overlay {
  position: absolute;
  left: 0;
  right: 0;
  background: #000;
  transition: all 0.4s ease-out;
  opacity: 0;
}

.cs_video_popup.active .cs_video_popup_overlay {
  opacity: 0.8;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.cs_video_popup_content {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  font-size: 0;
  text-align: center;
  transition: all 0.4s ease-out;
  transform: translateY(100px);
  opacity: 0;
  visibility: hidden;
  padding: 15px;
}

.cs_video_popup.active .cs_video_popup_content {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}

.cs_video_popup_content:after {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.cs_video_popup_container {
  display: inline-block;
  position: relative;
  text-align: left;
  background: #fff;
  max-width: 1380px;
  width: 100%;
  vertical-align: middle;
}

.cs_video_popup_container .embed_responsive {
  width: 100%;
}

.embed_responsive {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden;
  height: 100%;
}

.embed_responsive_16by9::before {
  display: block;
  content: '';
  padding-top: 56.25%;
}

.embed_responsive iframe,
.embed_responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.cs_video_popup_close {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  background: #d90d0d;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
}

.cs_video_popup iframe {
  width: 100%;
  height: 100%;
  position: absolute;
}

.cs_video_popup_close:before {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 1px;
  background: #fff;
  margin-left: -10px;
  transform: rotate(-45deg);
  transition: all 0.4s ease-in-out;
}

.cs_video_popup_close:after {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 1px;
  background: #fff;
  margin-left: -10px;
  transform: rotate(45deg);
  transition: all 0.4s ease-in-out;
}

.cs_video_popup_close:hover:before,
.cs_video_popup_close:hover:after {
  background: #000;
}

.cs_video_popup_layer {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
}

.cs_video_popup_align {
  overflow: hidden;
}
