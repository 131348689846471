/*--------------------------------------------------------------
18. Category
----------------------------------------------------------------*/
.cs_grid_list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 7.5%;
  @media (max-width: 1400px) {
    column-gap: 24px;
  }
  @media (max-width: 1199px) {
    column-gap: 24px;
  }
  @media (max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);
    gap: 30px;
  }
}
.cs_category.cs_style_1 {
  .cs_category_thumb {
    display: block;
  }
  .cs_category_btn {
    display: inline-flex;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $primary;
    padding: 10px 25px;
    color: $white;
    text-align: center;
    transition: all 0.3s ease;
    span:last-child {
      width: 0;
      transform: scale(0);
      display: inline-block;
      margin-bottom: -2px;
      transition: all 0.3s ease;
    }
  }
  &:hover {
    .cs_category_btn {
      background-color: $accent;
      span:last-child {
        width: 16px;
        margin-left: 10px;
        transform: scale(1);
      }
    }
  }
}
.cs_category.cs_style_2 {
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  display: block;
  max-width: 312px;
  .cs_category_thumb {
    padding-top: 100%;
    img {
      border-radius: 50%;
      border-radius: inherit;
      object-fit: cover;
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
    }
  }
  .cs_category_btn {
    min-width: 140px;
    padding: 10px 20px;
    background: rgba($primary, 0.1);
    backdrop-filter: blur(5px);
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
