/*--------------------------------------------------------------
16. Footer
----------------------------------------------------------------*/
.cs_footer_widget_title {
  margin-bottom: 19px;
}
.cs_footer_widget {
  margin-bottom: 60px;
  @media (max-width: 991px) {
    margin-bottom: 40px;
  }
}
.cs_text_widget {
  > * {
    margin-bottom: 26px;
    @media (max-width: 991px) {
      margin-bottom: 15px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.cs_menu_widget {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }
}
.cs_footer_menu_widget_2 {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 0;
}
.cs_social_links {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}
.cs_footer_bottom {
  padding: 30px 0;
  display: flex;
  justify-content: space-between;
  gap: 8px 30px;
  border-top: 1px solid $border;
  .cs_social_links {
    justify-content: flex-end;
  }
  @media (max-width: 991px) {
    flex-direction: column-reverse;
    text-align: center;
    padding: 20px 0;
    .cs_social_links {
      justify-content: center;
    }
  }
}
.cs_footer.cs_style_1 {
  &.cs_primary_bg {
    color: rgba($white, 0.8);
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: #fff;
    }
  }
}
