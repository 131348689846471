.echbay-sms-messenger div.phonering-alo-zalo, 
.echbay-sms-messenger div.phonering-alo-alo {
	background-color:#0084ff
}
.echbay-sms-messenger div.phonering-alo-sms {
	background-color:#f60
}
.echbay-sms-messenger div.phonering-alo-messenger {
	background-color:#e60f1e
}

.echbay-sms-messenger {
	width:45px
}
.echbay-sms-messenger a {
	line-height:45px;
        color: transparent;
display:block;
}
.echbay-sms-messenger {
	display:block
}
.echbay-sms-messenger div.phonering-alo-zalo {
	display: block
}

.echbay-sms-messenger div.phonering-alo-alo {
    background-image: url(https://webantam.com/wp-content/uploads/2023/04/messenger_1.webp);
}

.echbay-sms-messenger div.phonering-alo-zalo {
    background-image: url(https://webantam.com/wp-content/uploads/2023/04/zalo_1.webp);
}
.echbay-sms-messenger div.phonering-alo-messenger {
    background-image: url(https://webantam.com/wp-content/uploads/2023/04/call.webp);
    background-color: #e60f1e;
}
.echbay-sms-messenger div {
    margin: 14px 0;
    background: #0084FF center no-repeat;
    background-size: 70%;
    border-radius: 50%;
    box-shadow: 0 3px 10px #888;
}

.echbay-sms-messenger {
    text-align: center;
    right:20px;
    position: fixed;
    bottom: 20px;
    z-index: 999;
}

.echbay-sms-messenger div.phonering-alo-messenger{
	
	animation:2s ease-in-out 0s normal none infinite running ring-alo-circle-img-anim;
		
	}
	
	.echbay-sms-messenger div.phonering-alo-alo{
		
	animation:2s ease-in-out 0s normal none infinite running ring-alo-circle-img-anim;
	}
	
	.echbay-sms-messenger div.phonering-alo-zalo{
		
	animation:2s ease-in-out 0s normal none infinite running ring-alo-circle-img-anim;
	}
	
		@keyframes ring-alo-circle-img-anim {
				0% {
						transform:rotate(0deg) scale(1) skew(1deg);
		}
				10% {
						transform:rotate(-25deg) scale(1) skew(1deg);
		}
				20% {
						transform:rotate(25deg) scale(1) skew(1deg);
		}
				30% {
						transform:rotate(-25deg) scale(1) skew(1deg);
		}
				40% {
						transform:rotate(25deg) scale(1) skew(1deg);
		}
				50% {
						transform:rotate(0deg) scale(1) skew(1deg);
		}
				100% {
						transform:rotate(0deg) scale(1) skew(1deg);
		}
	}