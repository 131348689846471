.icon {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 10px;
  //margin-bottom: 10px;

  .icon-inner {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 40%;
      height: 40%;
      fill: #555; // Adjust the color of the icon
    }
  }
}

.text {
  font-size: 18px;
  color: #333; // Adjust the color of the text
  font-weight: bold;
}

.modal-open {
  padding-right: 0 !important;
}
.custom-modal {
  max-width: 60%;
 
}
