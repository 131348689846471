.category {
    max-width: 1320px;
    margin-left: auto;
    margin-right: auto;
}

@media (min-width: 640px) {
    .category {
        display: flex;
    }
}

@media (min-width: 640px) {
    .category {
        flex-direction: row;
    }
}

.list-product {
    padding-bottom: 30px;
}

@media (min-width: 640px) {
    .list-product {
        width: 75%;
    }
}

.sidebar {
    position: relative;
    display: none;
    width: 25%;
    margin-left: 2rem
        /* 32px */
    ;
    margin-right: 2rem
        /* 32px */
    ;
}

@media (min-width: 640px) {
    .sidebar {
        display: block;
    }
}

.banner {
    padding: 10px;
    width: 180px;
    position: absolute;
    top: 0;
    left: 0;
    transition: top 0.3s;
}

.banner.fixed {
    position: fixed;
    top: 100px;
    left: auto
}

.banner.hidden {
    opacity: 0;
    visibility: hidden;
}

@media (min-width: 768px) {
    .banner {
        width: 220px;
    }
}

@media (min-width: 1024px) {
    .banner {
        width: 280px;
    }
}

@media (min-width: 1280px) {
    .banner {
        width: 315px;
    }
}

.image {
    height: 400px;
    background-color: #acadae;
}

@media (min-width: 768px) {
    .image {
        height: 400px;
    }
}

@media (min-width: 1024px) {
    .image {
        height: 540px;
    }
}

.sidebar2 {
    display: block;
    padding-left: 4rem
        /* 64px */
    ;
    padding-right: 4rem
        /* 64px */
    ;
    position: relative;
}

@media (min-width: 640px) {
    .sidebar2 {
        display: none;
    }
}